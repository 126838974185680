<script lang="ts" setup>
    import type { ButtonProps } from '~/components/atm/Button.vue';

    interface ProductCardButtonsProps {
        isDarkBackground?: boolean;
        addToCartDisabled?: boolean;
        product: CMSProduct;
        variant?: CMSProductVariant;
        cartBusy: boolean;
        addToCartClicked: boolean;
        handleAddToCart: () => void;
        pdpUrl?: string;
        size?: ButtonProps['size'];
    }
    withDefaults(defineProps<ProductCardButtonsProps>(), {
        size: 'small',
    });
    const { canVariantQuickSell } = useProduct();
</script>
<template>
    <div class="flex w-full items-center gap-2 max-md:flex-col">
        <atm-button
            :styling="isDarkBackground ? 'solid-invert' : 'solid-secondary'"
            :disabled="addToCartDisabled || !canVariantQuickSell(product, variant?.id)"
            :loading="cartBusy && addToCartClicked"
            :size="size"
            @click="handleAddToCart">
            {{ $t('cta.cart.add') }}
        </atm-button>
        <lazy-atm-button
            v-if="pdpUrl"
            :to="pdpUrl"
            :styling="isDarkBackground ? 'outline-invert' : 'outline'"
            kind="link"
            :size="size">
            {{ $t('cta.viewDetails') }}
        </lazy-atm-button>
    </div>
</template>
